import React from "react";

import * as styles from './PageReferencesTitle.module.scss';
// import TriangleIcon from "../../icons/TriangleIcon/TriangleIcon";
import classNames from "classnames";
const className = classNames(styles.root, 'tmg-page-references-title');

const PageReferencesTitle = ({children}: {children: React.ReactNode}) => (
  <div className={className}>
    <h2 className={styles.headline}>
        {children}
      {/*{children}<span className={styles.icon}><TriangleIcon /></span>*/}
    </h2>
  </div>
);

export default PageReferencesTitle;