import React from "react";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import {useI18next} from "gatsby-plugin-react-i18next";
import {useLocation} from "@reach/router";
import {useTranslation} from "gatsby-plugin-react-i18next";

type propsType = {
    title: string,
    description: string,
}

const Seo = (props: propsType) => {

    const {t} = useTranslation();
    const { language } = useI18next();
    const { pathname } = useLocation();

    const url = t('baseUrl') + pathname;

    return (
        <GatsbySeo
            language={language}
            title={props.title}
            description={props.description}
            openGraph={{
                title:props.title,
                description:props.description,
                url: url,
            }}
        />
    )
};

export default Seo;